import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";

import Button from "components/CustomButtons/Button.js";
import event1 from "assets/img/event1.jpg";
import event2 from "assets/img/event2.jpg";
import event3 from "assets/img/event3.png";
import event4 from "assets/img/event4.png";
import event5 from "assets/img/event5.png";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import christian from "assets/img/faces/christian.jpg";

const useStyles = makeStyles(blogsStyle);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
    className={className}
    style={{ ...style, marginRight: "-30px", position: "absolute", top: "30%", rigth: "0", zIndex: "100"  }}
    onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{... style, marginLeft: "-30px", position: "absolute", top: "30%", left: "0", zIndex:"100" }}
      onClick={onClick}
    />
  );
}



export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

  };
  return (
    <div className="cd-section" {...rest}>

      {/* Testimonials 2 START */}
      <div
        className={
          classes.testimonials +
          " " +
          classes.sectionDark +
          " " +
          classes.testimonial2
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title} style={{textAlign: "center"}}>Current Events</h2>
              <Carousel {...settings} style={{marginTop: "-50px"}}>
              <div>
                <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                    <img src={event5} alt="..."/>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${event5})`, opacity: "1"}}
                  />
                </CardHeader>
                <CardBody plain>
                  <Danger>
                    <h6 className={classes.cardCategory}>Local Community Donations</h6>
                  </Danger>
                  <h3 className={classes.cardTitle}>
                      HAW Donates Robotics Kits For STEM Learning
                  </h3>
                  <h5 className={classes.description}>
                    We've donated kits to hundreds of local students. The picture above shows 
                    a small subset of students who recently received their ElEGOO Starter Kits. 
                    They will use these kits to learn to build on the arduino platform. HAW has donated 
                    many other Robotics and STEM kits.
                  </h5>
                  {/*
                  <Button round color="primary">
                    Read More
                  </Button>
                  */}
                </CardBody>
              </Card>
                </div>
                <div>
                <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                    <img src={event1} alt="..."/>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${event1})`, opacity: "1"}}
                  />
                </CardHeader>
                <CardBody plain>
                  <Danger>
                    <h6 className={classes.cardCategory}>Local Community Education</h6>
                  </Danger>
                  <h3 className={classes.cardTitle}>
                      Python 100 class (Ages 11 - 17)
                  </h3>
                  <h5 className={classes.description}>
                    We currently teach 3 classes. At the end of the python course, students
                    will have built 2-3 games in python. The picture above shows one of the many group classes 
                    we offer. Other classes include C++, javascript, arduino, and web development.
                  </h5>
                  {/*
                  <Button round color="primary">
                    Read More
                  </Button>
                  */}
                </CardBody>
              </Card>
                </div>
                <div>
                <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                    <img src={event4} alt="..."/>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${event4})`, opacity: "1"}}
                  />
                </CardHeader>
                <CardBody plain>
                  <Danger>
                    <h6 className={classes.cardCategory}>True Decentralization</h6>
                  </Danger>
                  <h3 className={classes.cardTitle}>
                      Local Nodes Launched In Hawaii
                  </h3>
                  <h5 className={classes.description}>
                    We've recently launched our bare metal nodes here in Hawaii in order to support 
                    true decentralization. We have plans to keep other remote nodes up for extra redundancy.
                    This improves the reliability of our core node connection.
                  </h5>
                  {/*
                  <Button round color="primary">
                    Read More
                  </Button>
                  */}
                </CardBody>
              </Card>
                </div>
                <div>
                <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                    <img src={event3} alt="..."/>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${event3})`, opacity: "1"}}
                  />
                </CardHeader>
                <CardBody plain>
                  <Danger>
                    <h6 className={classes.cardCategory}>Local Hiring</h6>
                  </Danger>
                  <h3 className={classes.cardTitle}>
                      Local Employees
                  </h3>
                  <h5 className={classes.description}>
                    Daniel Laugenberg is an a full-stack web developer from Hawaii. He earned his AS degree for 
                    Kapiolani Community college, where he found his passion for programming. UPDATE: He was employed
                    by our affiliated company but had to leave due to personal reasons. We remain in close contact with 
                    Daniel and hope to work with him again in the future. 
                  </h5>
                  {/*
                  <Button round color="primary">
                    Read More
                  </Button>
                  */}
                </CardBody>
              </Card>
                </div>
                

                <div>
                <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                    <img src={event2} alt="..."/>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${event2})`, opacity: "1"}}
                  />
                </CardHeader>
                <CardBody plain>
                  <Danger>
                    <h6 className={classes.cardCategory}>Stake Pool</h6>
                  </Danger>
                  <h3 className={classes.cardTitle}>
                      We Minted Our Fourth Block!
                  </h3>
                  <h5 className={classes.description}>
                    Our pool is steadily growing and increasing in size thanks to our many delegators. We still have 
                    a long way to go in offering our delegators consistent rewards. We have many plans to keep this momentum 
                    going and also plans to expand so that we can become a major influence in Hawaii.
                  </h5>
                  {/*
                  <Button round color="primary">
                    Read More
                  </Button>
                  */}
                </CardBody>
              </Card>
                </div>
                
                
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 2 END */}
      
    </div>
  );
}
