import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Computer from "@material-ui/icons/Computer";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import AutoRenew from "@material-ui/icons/Autorenew";
import Home from "@material-ui/icons/Home";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      {/*
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Objectives</h2>
          <h5 className={classes.description}>
            We support the decentrilization of the Cardano Network. Our nodes are implemented for high availability
            and Security. We also plan to give back to the Cardano Community and Our local Community buy building DAPs and Creating Jobs. 
            Read more about us below.
          </h5>
        </GridItem>
      </GridContainer>
      */}
      <div>
        <GridContainer>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Architecture"
              description="HAW stake pool is implemented with local nodes here in Honolulu to support true 
              decentralization. We do have other nodes located around the world for extra redundancy to 
              ensure our core node will always be connected to the cardano network."
              icon={Computer}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Security"
              description="We implement the most current security practices in our network of nodes. 
              We understand that things may seem intimidating to first time stakers but one of the 
              great things about cardano is you will never give up control of any of your tokens. "
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Contribution to Cardano"
              description="We plan to create our own DAPs on Cardano. Our team has the knowledge and experience to create useful DAPs that we hope 
              will attract more users to the Cardano Network. We are currently working on many DAP collaborations which integrating cardano into 
              games, real estate, and much more."
              icon={AutoRenew}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Commitment to Hawaii"
              description="Locally, we hope to create more jobs. Too many of our local developers are looking for work away from home. 
              Through development, we will do out part to help increase need for software developers and drive a need for local jobs.
              We are also helping to prepare Hawaii's youth by offering classes in programming and robotics. Check out our Current Events section for more info."
              icon={Home}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
